import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { usePreviewMode, useStoryblok } from '@b2c-web-marketing/shared/storyblok-sdk';

import {
  getPage,
  getHeader,
  getFooter,
  getSalesforceChat,
  getLocationModal,
  getCookieBanner,
  getSharedSeoSchemaItems,
} from '../api';
import { componentRelations } from '../api/relations/relations';
import { PageItem } from '../api/types/basic';
import { getFooterV2 } from '../api/utils/getFooterV2';
import { getHeaderV2 } from '../api/utils/getHeaderV2';
import { getSettings } from '../api/utils/getSettings';
import { Layout } from '../components/Layout/Layout';
import { getURLFromStoryblokLink } from '../utils/getURLFromStoryblokLink/getURLFromStoryblokLink';
import { handlePageItemLoad } from '../utils/handlePageItemLoad/handlePageItemLoad';
import { NEXT_ISR_REVALIDATE_SECONDS } from '../utils/isr';

export function Index({
  data,
  preview,
  header,
  footer,
  salesforceChat,
  locationModal,
  cookieBanner,
  useV2Header,
  useV2Footer,
  keepTopMargin,
}) {
  usePreviewMode(preview);
  const story = useStoryblok<PageItem>(data, preview, handlePageItemLoad);

  if (!data || !story?.content?.body) {
    return null;
  }

  return (
    <Layout
      header={header}
      footer={footer}
      metatags={data?.content?.metatags}
      salesforceChat={salesforceChat}
      locationModal={locationModal}
      cookieBanner={cookieBanner}
      useV2Header={useV2Header}
      useV2Footer={useV2Footer}
      keepTopMargin={keepTopMargin}
    >
      <StoryblokComponent blok={story.content} />
    </Layout>
  );
}

export const getStaticProps = async ({ locale, preview = false }) => {
  locale = locale === 'default' ? 'en-gb' : locale;

  const settings = await getSettings({ slug: `${locale}/settings` });
  const headerV2Settings = settings?.content?.headerV2SettingsPerPage?.find(
    (setting) => getURLFromStoryblokLink(setting.link) === `${locale}/404`
  );

  const useV2Header = (settings?.content?.useV2Header && headerV2Settings?.useV2Header) || false;
  const useV2Footer = settings?.content?.useV2Footer || false;
  const keepTopMargin = useV2Header && headerV2Settings?.keepTopMargin;
  const keepSolidBackground = useV2Header && headerV2Settings?.keepSolidBackground;

  const header = useV2Header
    ? await getHeaderV2({ slug: `${locale}/header-v2` })
    : await getHeader({ slug: `${locale}/header` });
  const footer = useV2Footer
    ? await getFooterV2({ slug: `${locale}/footer-v2`, relations: componentRelations })
    : await getFooter({ slug: `${locale}/footer` });

  const locationModal = await getLocationModal({ slug: `${locale}/location-modal` });
  const data = await getPage({ slug: `${locale}/404`, relations: '' });
  const cookieBanner = await getCookieBanner({ slug: `${locale}/cookie-consent` });
  const salesforceChat = await getSalesforceChat({ slug: `${locale}/salesforce-chat` });
  const sharedseoschemaItems = await getSharedSeoSchemaItems({
    startsWith: `${locale}/shared-schemas`,
  });

  return {
    props: {
      data,
      sharedseoschemaItems: sharedseoschemaItems || null,
      preview,
      header: { ...header.content, keepSolidBackground },
      footer: footer.content,
      salesforceChat: salesforceChat ? salesforceChat.content : null,
      locationModal: locationModal.content,
      cookieBanner: cookieBanner ? cookieBanner.content : null,
      useV2Header,
      useV2Footer,
      keepTopMargin,
    },
    revalidate: NEXT_ISR_REVALIDATE_SECONDS,
  };
};

export default Index;
